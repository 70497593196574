/* eslint-disable jsx-a11y/anchor-is-valid */
import logo from "./DLMS-logo.png";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";

import AudioBook from "./images/audio.png";
import VideoBook from "./images/video.png";
import VisualBook from "./images/visual.png";
import FlipBook from "./images/flip.png";

function App() {
  useEffect(() => {
    AOS.init({
      duration: "2000",
      easing: "ease-in-out",
      once: true,
    });
  }, []);
  return (
    <div className="relative overflow-hidden w-full min-h-screen flex flex-col justify-center items-center">
      <div>
        <div className="absolute -top-[130px] -left-[130px] lg:-left-[240px] lg:-top-[240px] w-[312px] h-[312px] lg:w-[681px] lg:h-[681px]  bg-gradient blur-[120px] rounded-full rotate-[50deg] animate-pulse pointer-events-none"></div>

        <div className="absolute -bottom-[130px] -right-[130px] lg:-bottom-[240px] lg:-right-[240px] w-[312px] h-[312px] lg:w-[681px] lg:h-[681px] bg-gradient  blur-[120px] rounded-full rotate-[50deg] animate-pulse pointer-events-none"></div>
      </div>

      <div>
        <section className="header conatiner text-center mx-auto">
          <h1 className="md:text-[100px] text-[66px] font-medium leading-[54px] md:leading-[110px]  mt-5">
            DLMS
          </h1>
          <p className=" text-[18px] md:text-[30px]">(Digital Library Management System)</p>

          <p className=" text-[16px] md:text-[20px] font-medium tracking-wide leading-6 text-zinc-500 px-4 w-full xl:w-1/2 mx-auto mt-5">
            A digital library provides limitless knowledge at your fingertips,
            accessible anytime, anywhere on any device, ensuring instant
            learning wherever you go. As everything shifts to digital, these
            libraries not only offer unparalleled convenience but also support a
            sustainable future. By reducing the need for physical copies, they
            help us inch closer to net-zero goals-minimizing paper consumption,
            cutting transportation emissions, and decreasing storage demands.
            It's a smarter, greener way to explore, learn, and thrive, all while
            leading to a healthier planet.
          </p>
        </section>

        <section className=" mb-40 xl:mb-28 mt-16 ">
          <div className="relative flex justify-center items-center">
            <div className="relative items-center mt-10 text-center">
              <img
                src={logo}
                alt="DLMS Cloud Logo"
                className="w-48 lg:w-60 2xl:w-96 mb-2"
              />
              <h2 className="text-[28px] lg:text-[35px] 2xl:text-[60px] font-extrabold text-[rgba(0,181,255,1)] tahoma-font tracking-wide">
                DLMS CLOUD
              </h2>
            </div>

            <div
              className="absolute -top-8 left-[4%] lg:top-[5rem] sm:left-[15%] lg:left-[8%] xl:left-[18%]  "
              data-aos="fade-up-left"
            >
              <BookCategory label="Audio Book" icon={AudioBook} />
            </div>
            <div
              className="absolute top-8 right-[4%] lg:top-[5rem] sm:right-[15%] lg:right-[8%] xl:right-[18%] "
              data-aos="fade-up-right"
            >
              <BookCategory label="Video Book" icon={VideoBook} />
            </div>
            <div
              className="absolute -bottom-[2rem] left-[5%] lg:-bottom-[4rem] xl:bottom-[1rem]  sm:left-[18%] md:left-[8%] lg:left-[23%] "
              data-aos="fade-down-left"
            >
              <BookCategory label="Flip Book" icon={FlipBook} />
            </div>
            <div
              className="absolute -bottom-[5rem] right-[4%] lg:-bottom-[4rem] xl:bottom-[1rem]  sm:right-[18%] md:right-[8%] lg:right-[23%] "
              data-aos="fade-down-right"
            >
              <BookCategory label="Visual Book" icon={VisualBook} />
            </div>
          </div>
        </section>

        <section className="footer container-fluid mx-auto px-5 lg:px-8 2xl:px-28 mb-5">
          <div className="flex flex-col lg:flex-row justify-between">
            <div className="lg:basis-4/12 xl:basis-3/12 order-2 lg:order-1">
              <div className="flex flex-col md:flex-row justify-between">
                <div>
                  <p className="text-neutral-600  lg:mb-4 jakarta tracking-wide text-sm  md:text-base">
                    For Demo get in touch with Us:
                  </p>
                  <a
                    href="https://mail.google.com/mail/u/0/?to=demo@dlmscloud.com&amp;su=Message+from+DLMS+CLOUD&amp;body=https://dlmscloud.com&amp;fs=1&amp;tf=cm"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#0092CE] font-semibold text-xl md:text-[24px] tracking-wide underline"
                  >
                    demo@dlmscloud.com
                  </a>
                </div>
                <div>
                  <p className="lg:hidden md:text-xl text-lg mt-4">
                    <FontAwesomeIcon icon={faPhoneVolume} />
                    <span className="ms-3"> +91 91529 67106</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:basis-4/12 xl:basis-3/12 order-1 lg:order-2 text-center ">
              <h1 className="text-[52px] 2xl:text-[90px] sacramento">
                Stay Tuned
              </h1>
            </div>
            <div className="hidden lg:block lg:basis-4/12 xl:basis-3/12 order-3">
              <div className="text-end mt-10">
                <p className="text-2xl mx-5">
                  <FontAwesomeIcon icon={faPhoneVolume} />
                  <span className="ms-3"> +91 91529 67106</span>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
const BookCategory = ({ label, icon }) => {
  return (
    <div className="flex justify-center items-center w-[116px] h-9 md:w-48 md:h-14 bg-[rgba(248,248,248,1)] shadow-[0px_15px_9.9px_0px_rgba(0,0,0,0.16)] rounded-full">
      <span className="text-2xl mx-2">
        <img src={icon} alt="icon" className="w-4 md:w-auto" />
      </span>
      <span className="font-semibold text-gray-700 text-[10px] md:text-lg">
        {label}
      </span>
    </div>
  );
};

export default App;
